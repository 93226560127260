import { redirect } from 'react-router-dom';
import { PathPrivate, PathPublic } from './constants';
import * as auth from '../config/auth';

export function getInitialPath() {
  return PathPrivate.Dashboard;
}

export function generatePath(
  pathTemplate: string,
  params: Record<string, string | number>,
) {
  let path = pathTemplate;
  Object.keys(params).forEach((key) => {
    path = path.replace(`:${key}`, String(params[key]));
  });
  return path;
}

export function ensureAuthAndRole(path?: PathPrivate | PathPublic) {
  if (!auth.isLoggedIn()) {
    return redirect(PathPublic.Login);
  }

  if (!auth.isProgramManager()) {
    return redirect(PathPublic.Denied);
  }

  return path ? redirect(path) : null;
}

export function ensureAuth() {
  if (!auth.isLoggedIn()) {
    return redirect(PathPublic.Login);
  }

  return null;
}
