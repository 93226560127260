import * as React from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {
  Box,
  Typography,
  Container,
  Grid,
  CircularProgress,
  Snackbar,
  Link,
} from '@mui/material';
import { AccountBalance as AccountBalanceIcon } from '@mui/icons-material';
import * as mutations from './mutations';
import { StyledPaper, StyledIconWrapper, StyledButton } from './styles';

export function LinkBankAccount() {
  const [linkToken, setLinkToken] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState(false);

  const generateToken = React.useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await mutations.createLinkToken();

      if (!response.ok) {
        throw new Error('Failed to create link token');
      }

      const data = await response.json();
      setLinkToken(data.link_token);
    } catch (err) {
      console.error('Error creating link token:', err);
      setError(
        err instanceof Error
          ? err.message
          : 'Failed to initialize Plaid. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onSuccess = React.useCallback(async (publicToken: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await mutations.setAccessToken(publicToken);

      if (!response.ok) {
        throw new Error('Failed to exchange public token');
      }

      setSuccess(true);
    } catch (err) {
      console.error('Error exchanging public token:', err);
      setError('Failed to link bank account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  React.useEffect(() => {
    generateToken();
  }, [generateToken]);

  const handleRetry = () => {
    generateToken();
  };

  return (
    <Container maxWidth="sm">
      <Box my={8}>
        <StyledPaper elevation={3}>
          <StyledIconWrapper>
            <AccountBalanceIcon fontSize="large" style={{ color: 'white' }} />
          </StyledIconWrapper>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            fontWeight="bold"
          >
            Link Your Bank Account
          </Typography>
          <Typography
            variant="body1"
            paragraph
            align="center"
            color="textSecondary"
          >
            Securely connect your bank account using Plaid&rsquo;s trusted
            platform.
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
              {error && (
                <Typography color="error" align="center" gutterBottom>
                  {error}
                </Typography>
              )}
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => (linkToken ? open() : handleRetry())}
                disabled={isLoading || !ready}
                fullWidth
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : !linkToken ? (
                  'Retry Initialization'
                ) : (
                  'Connect Bank Account'
                )}
              </StyledButton>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Typography variant="caption" color="textSecondary" align="center">
              By connecting your account, you agree to our Terms of Service and{' '}
              <Link
                href="https://onrampfleet.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                underline="always"
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </Box>
        </StyledPaper>
      </Box>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        message="Bank account linked successfully!"
      />
    </Container>
  );
}
