import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { PathPublic, PathPrivate } from '@/aggregator/paths/constants';
import { NavLink } from 'react-router-dom';
import { Logo } from './Logo';
import { HeaderMenuMobile } from './HeaderMenuMobile';
import { HeaderMenuDesktop } from './HeaderMenuDesktop';

export type PagesType = {
  text: string;
  to: string;
};

export const pages = [
  { text: 'Dashboard', to: PathPrivate.Dashboard },
  { text: 'Carriers', to: PathPrivate.Carriers },
  { text: 'Merchants', to: PathPrivate.Merchants },
  { text: 'Users', to: PathPrivate.Users },
];

type HeaderPropsType = {
  isBasic?: boolean;
};

export function Header(props: HeaderPropsType) {
  const { isBasic } = props;
  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isBasic && (
              <HeaderMenuMobile
                pages={[...pages, { text: 'Logout', to: PathPublic.Logout }]}
              />
            )}
            <Logo isBasic={isBasic} />
            {!isBasic && <HeaderMenuDesktop pages={pages} sx={{ ml: 3 }} />}
          </Box>

          <Button
            color="inherit"
            component={NavLink}
            to={PathPublic.Logout}
            sx={{
              display: {
                xs: isBasic ? 'block' : 'none',
                md: 'block',
              },
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
