import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OnrampLogo from '@/aggregator/onramp-logo.svg';

type LogoPropsType = {
  isBasic?: boolean;
};

export function Logo({ isBasic = false }: LogoPropsType) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: 1 }}>
        <img
          src={OnrampLogo}
          alt="Onramp Logo"
          style={{
            maxHeight: '25px',
          }}
        />
      </Box>
      {!isBasic && (
        <Typography
          variant="h6"
          noWrap
          sx={{
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Program
        </Typography>
      )}
    </Box>
  );
}
