import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export async function createLinkToken() {
  return await axios
    .post(`${Config.PAYMENTGATEWAY_API_URL}/plaid/create_link_token`)
    .then((response) => response)
    .catch((error) => error);
}

export async function setAccessToken(publicToken: string) {
  return await axios
    .post(`${Config.PAYMENTGATEWAY_API_URL}/plaid/set_access_token`, {
      publicToken,
    })
    .then((response) => response)
    .catch((error) => error);
}
