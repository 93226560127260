import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';
import * as mutations from './mutations';

const validationSchemaObject = Yup.object().shape({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email().required('Required field'),
  password: Yup.string().required('Required field'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

const FORM_INPUT_FIELDS = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

export function CarrierFleetAppAddUser() {
  const navigate = useNavigate();
  const { carrierId = '' } = useParams();

  const handleOnClickClose = (shouldRefetch: boolean) => {
    const pathUpdated = generatePath(PathPrivate.CarrierFleetAppUsers, {
      carrierId: carrierId,
    });

    navigate(pathUpdated, { state: { shouldRefetch } });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={(values: any) => {
        const payloadValues = {
          user: {
            ...values,
            role: 'FLEET_MANAGER',
          },
          carrierUuid: carrierId,
        };

        mutations
          .addUser(carrierId, payloadValues)
          .then((response) => {
            if (response.status === 200) {
              mutations
                .setPassword({
                  password: values.password,
                  temporary: true,
                  entityUuid: response.data.user.entityUuid,
                  identityUuid: response.data.user.identityUuid,
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => {
            handleOnClickClose(true);
          });
      }}
    >
      {({ handleChange, handleSubmit }) => (
        <Dialog open onClose={() => handleOnClickClose(false)}>
          <DialogTitle>Add User</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              {FORM_INPUT_FIELDS.map((field) => {
                const { value, label } = field;
                return (
                  <TextField
                    key={`${value}-${label}`}
                    id={value}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    sx={{ marginBottom: 1 }}
                  />
                );
              })}
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={() => handleOnClickClose(false)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
