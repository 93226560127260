import axios from 'axios';
import { Config } from '@/aggregator/config/env';
import * as types from './types';

export async function getUsers(
  carrierId: string,
): Promise<types.UsersResponseType> {
  return await axios
    .get(
      `${Config.CARRIER_API_URL}/carriers/${carrierId}/users?roles=FLEET_MANAGER`,
    )
    .then((response) => response.data as types.UsersResponseType)
    .catch((error) => {
      console.error('Error fetching users:', error);
      throw error;
    });
}

export async function deactivateUser(identityUuid: string) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/users/${identityUuid}/deactivate`)
    .then((response) => response)
    .catch((error) => error);
}

export async function activateUser(identityUuid: string) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/users/${identityUuid}/activate`)
    .then((response) => response)
    .catch((error) => error);
}
