import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { PagesType } from './Header';

type HeaderMenuDesktopPropsType = BoxProps & {
  pages: PagesType[];
};

export function HeaderMenuDesktop(props: HeaderMenuDesktopPropsType) {
  const { pages, sx, ...rest } = props;
  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, ...sx }} {...rest}>
      {pages.map((page) => (
        <Button
          key={page.text}
          component={NavLink}
          to={page.to}
          sx={{
            my: 2,
            color: 'black',
            display: 'block',
            '&.active': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          }}
        >
          {page.text}
        </Button>
      ))}
    </Box>
  );
}
