import * as React from 'react';
import { Box, Dialog, TextField, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';
import * as mutations from './mutations';

const cancelButtonStyle = {
  height: 40,
  padding: '15px',
  background: '#fff',
  border: '1px solid #000',
  '&:hover': {
    color: '#585858',
  },
  color: '#000',
  textTransform: 'none',
};

const submitButtonStyle = {
  height: 40,
  padding: '15px',
  background: '#000',
  '&:hover': {
    backgroundColor: '#585858',
  },
  color: '#fff',
  textTransform: 'none',
};

const ERROR_MESSAGE =
  'Unable to reset password. Please try again later or contact support for assistance.';

export function CarrierFleetAppUserResetPassword() {
  const navigate = useNavigate();
  const { carrierId = '', userId = '' } = useParams();

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleOnClose = () => {
    const pathUpdated = generatePath(PathPrivate.CarrierFleetAppUsers, {
      carrierId,
    });

    navigate(pathUpdated);
  };

  const handleSubmitPassword = () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords must match.');
      return;
    }
    if (password === '' && confirmPassword === '') {
      setErrorMessage('Invalid password.');
      return;
    }
    if (password.length < 4) {
      setErrorMessage('Password must be at least 4 characters long.');
      return;
    }

    const payload = {
      password,
      temporary: true,
      entityUuid: userId,
    };

    mutations
      .resetUserPassword(payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Password reset successfully!');
          handleOnClose();
        } else {
          toast.error(ERROR_MESSAGE);
        }
      })
      .catch(() => {
        toast.error(ERROR_MESSAGE);
      });
  };

  return (
    <Dialog open onClose={handleOnClose}>
      <Box m={2}>
        <Typography variant="h5" mb={2}>
          Reset Password
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            id="password"
            label="New Password"
            variant="outlined"
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
            sx={{ marginBottom: 1, width: 408 }}
          />
          <TextField
            id="confirmPassword"
            label="Confirm New Password"
            variant="outlined"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setErrorMessage('');
            }}
            sx={{ marginBottom: 1, width: 408 }}
          />
        </Box>
        {errorMessage !== '' && (
          <Box m={1} my={2}>
            <Typography variant="body1" sx={{ color: 'red' }}>
              {errorMessage}
            </Typography>
          </Box>
        )}
        <Box my={1}>
          <Button
            sx={[cancelButtonStyle, { width: 200, marginRight: 1 }]}
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            sx={[submitButtonStyle, { width: 200 }]}
            onClick={handleSubmitPassword}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
